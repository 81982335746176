export {isShowAsLoggedIn, showAsLoggedOut, setLoginStatus, getLoginKennung};

function isShowAsLoggedIn() {
    try {
        return localStorage["kim.login.showAsLoggedIn"] === "true";
    } catch (error) {
        return false;
    }
}

function showAsLoggedOut() {
    try {
        localStorage["kim.login.showAsLoggedIn"] = false;
        document.cookie = "cclogin-data=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "club=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    } catch (error) {}
}

function setLoginStatus(status = false, kennung = '') {
    try {
        const aktuellerStatus = localStorage["kim.login.showAsLoggedIn"];
        const zielStatus = (status) ? "true" : "false";
        if(aktuellerStatus !== zielStatus) {
            const neuerStatus = (status) ? 'eingeloggt' : 'ausgeloggt';
            const neuerStatusEvent = new CustomEvent("login.change", { detail: {neuerStatus:  neuerStatus}});
            window.dispatchEvent(neuerStatusEvent);
        }
        localStorage["kim.login.loginUsername"] = kennung;
        localStorage["kim.login.showAsLoggedIn"] = status;
        const updateStatusEvent = new CustomEvent("login.status");
        window.dispatchEvent(updateStatusEvent);
    } catch (e) { }
}

function getLoginKennung() {
    try {
        return localStorage["kim.login.loginUsername"] || "";
    } catch(error) {
        return "";
    }
}