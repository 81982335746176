export function insertIntoDom(container, html) {
    const range = document.createRange();
    range.setStart(container, 0);
    range.setEnd(container, 0);
    container.appendChild(range.createContextualFragment(html));
}

export function isNotIE11() {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    return !isIE11;
}

export function loadJS(container, FILE_URL, async = true, loadCallback) {
    let scriptEle = document.createElement("script");

    scriptEle.setAttribute("src", FILE_URL);
    scriptEle.setAttribute("type", "text/javascript");
    scriptEle.setAttribute("async", async);

    scriptEle.addEventListener("load", () => {
        loadCallback()
    });

    container.appendChild(scriptEle);
}