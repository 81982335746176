import { insertIntoDom, isNotIE11 } from "../../libraries/helper";
import { isShowAsLoggedIn, showAsLoggedOut } from "../../libraries/login-status";
import { addOutsideclickListener } from "../../libraries/outside-click";

class GlobalHeaderLogin extends HTMLElement {

    connectedCallback() {
        this.initLogoutHandler();
        this.initLoginStatusEvent();
        this.showLoginStatus(this.getHeaderLoginLink());
        if (isNotIE11()) {
            this.fetchLayerContent();
            this.initClickHandling();
        }
    }

    initLoginStatusEvent() {
        window.addEventListener("login.status", (event) => {
            this.showLoginStatus(this.getHeaderLoginLink());
        });
    }

    showLoginStatus(element) {
        const istNutzerEingeloggt = isShowAsLoggedIn();
        if (istNutzerEingeloggt) {
            element.setAttribute('status', "eingeloggt");
        } else {
            element.setAttribute('status', "ausgeloggt");
        }
    }

    getHeaderLoginLink() {
        return this.querySelector('[interaction=link-login]');
    }

    initClickHandling() {
        this.addEventListener('click', (event) => { this.clickLink(event); });
    }

    clickLink(event) {
        if (this.shouldVisibilityBeToggled(event.target)) {
            event.preventDefault();
            this.toggleVisibility();
        }
    }

    shouldVisibilityBeToggled(elementClicked) {
        if (!this.isOverlayNotEmpty) {
            return false;
        }
        const isHeaderLinkClicked = elementClicked === this.getHeaderLoginLink();
        const isHeaderLinkChildClicked = this.getHeaderLoginLink().contains(elementClicked);
        const isCloseButtonClicked = elementClicked.getAttribute('interaction') === "headeroverlay-schliessen";

        return isHeaderLinkClicked || isHeaderLinkChildClicked || isCloseButtonClicked;
    }

    clickOutside(event) {
        if (this.isOverlayClosed) {
            return true;
        }
        const inoverlay = event.target.closest("header-global-login");
        if (!inoverlay) {
            this.toggleVisibility();
        }
    }

    toggleVisibility() {
        this.querySelector(".layer").classList.toggle('open');
    }

    async fetchLayerContent() {
        const response = await fetch('/include/login/header-overlay');

        if (response.status === 200) {
            try {
                const html = await response.text();
                insertIntoDom(this, html);
                addOutsideclickListener(this.overlay, () => { this.clickOutside(event); });
            } catch (error) {
                console.log(error);
            }
        }
    }

    initLogoutHandler() {
        document.addEventListener("click", ({ target }) => {
            const element = target.closest("a.oLogout, [interaction=link-logout]");
            if (element) {
                showAsLoggedOut();
            }
        });
    }

    get overlay() {
        return this.querySelector(".layer");
    }

    get isOverlayClosed() {
        return !this.overlay.classList.contains("open");
    }

    get isOverlayNotEmpty() {
        const isLayerThere = !!this.overlay;
        if (isLayerThere) {
            return this.overlay.children.length !== 0;
        }
        return false;
    }
}

customElements.define('header-global-login', GlobalHeaderLogin);


class HeaderLoginResponse extends HTMLElement {
    connectedCallback() {
        const container = this.closest('overlay-login-container section');
        const htmlContent = this.innerHTML;
        container.innerHTML = "";
        insertIntoDom(container, htmlContent);
    }
}
customElements.define('header-login-response', HeaderLoginResponse);