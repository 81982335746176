let hatGeklickt = false;
let x = 0;
let y = 0;

export function addOutsideclickListener(dialog, closeFunction) {
    document.addEventListener('mousedown', (event) => mousedownHandler(event, dialog));
    document.addEventListener('mouseup', (event) => mouseupHandler(event, dialog, closeFunction));
}

function mousedownHandler(event, dialog) {
	const clickOutsideDialog = isClickOutsideDialog(event, dialog);
	if (clickOutsideDialog) {
		hatGeklickt = true;
	}
}

function mouseupHandler(event, dialog, closeFunction) {
	if (hatGeklickt === true && isClickOutsideDialog(event, dialog)) {
		closeFunction(event);
		hatGeklickt = false;
	}
}

function isClickOutsideDialog(event, dialog) {
	x = event.offsetX;
	y = event.offsetY;
	const dialogHeight = dialog.getBoundingClientRect().height;
    const dialogWidth = dialog.getBoundingClientRect().width;
    return x < 0 || x > dialogWidth || y < 0 || y > dialogHeight;
}
